document.addEventListener('DOMContentLoaded', function () {

    let $cursorX = 0, $cursorY = 0, $top = 0, $left = 0, $leftd = 0, $topd = 0;

    $(document).mousemove(e => {
        $cursorX = e.clientX;
        $cursorY = e.clientY;
    });

    setInterval(() => {
        $leftd += ($cursorX - $leftd) / 20;
        $topd += ($cursorY - $topd) / 20;
        $('.dot').css({ left: $leftd + 'px', top: $topd + 'px' });
    }, 1);

    $('.project-item-wrap').mouseenter(function () {
        $('.dot').addClass('more');
    });
    $('.project-item-wrap').mouseleave(function () {
        $('.dot').removeClass('more');
    });

});